import dynamic from 'next/dynamic';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { setGlobalLoading, setShowLoggedInPopup, setShowRegisterPopup } from '../../_redux/actions';

const LocationModal = dynamic(import('../modals/LocationModal'));
const OurServices = dynamic(import('./OurServices'));
const WhyChooseUs = dynamic(import('./WhyChooseUs'));
const HowItWorks = dynamic(import('./HowItWorks'));
const UserCounter = dynamic(import('./UserCounter'));
const MobileAppSection = dynamic(import('../Utils/MobileAppSection'));

const mapStateToProps = (state) => ({
  isLoggedIn: state.user.isLoggedIn,
  serviceSegments: state.user.serviceSegments,
  siteData: state.user.siteData,
  userLocation: state.user.userLocation,
  segmentErrMessage: state.user.segmentErrMessage,
  globalLoading: state.user.globalLoading,
});

const mapDispatchToProps = (dispatch) => ({
  setGlobalLoading: (data) => {
    dispatch(setGlobalLoading(data));
  },
});

function HomePage(props) {
  const {
    siteData,
    serviceSegments,
    isLoggedIn,
    userLocation,
    segmentErrMessage,
    setGlobalLoading,
    globalLoading,
    siteSSRData,
  } = props;
  const [showLocationModal, setShowLocationModal] = useState(true);
  const router = useRouter();

  useEffect(() => {
    if (localStorage.getItem('currentLatLong')) {
      setShowLocationModal(false);
    } else {
      setShowLocationModal(true);
    }
  }, []);

  useEffect(() => {
    if (serviceSegments.length > 0) {
      setGlobalLoading(false);
    }
  }, [serviceSegments]);

  return (
    <>
      <section className="mt-70 home-slider">
        {isLoggedIn && Object.keys(userLocation).length == 0 && showLocationModal && <LocationModal />}
        <div>
          {siteData.banner && (
            <img alt="" className="d-block w-100" src={siteData?.banner?.logo} width={1920} height={500} />
          )}
        </div>
      </section>

      <div className="container py-md-5 py-4">
        <OurServices />
        {serviceSegments.length == 0 && <p className="text-center mb-4 fs-18">{segmentErrMessage}</p>}
        {!isLoggedIn && <p className="text-center mb-4 fs-18">Login to see services</p>}
      </div>
      <section className="py-md-5 py-4 why-choose-us">
        <div className="container">
          <WhyChooseUs />
        </div>
      </section>

      <div className="container py-md-5 py-4">
        <HowItWorks />
      </div>
      <section className="user-counter">
        <div className="container py-md-5 py-4">
          <UserCounter />
        </div>
      </section>
      <MobileAppSection />
    </>
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
